/* GENERAL CSS */
* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  /* border: 1px solid blue; */ }
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0.45rem; 
}

::-webkit-scrollbar-track {
  background: #090c1d;
}

::-webkit-scrollbar-thumb {
  background: #191939;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #eeaf0e;
}

main {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  width: 100%;
}

.content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  /* border-top-left-radius: 12px; */
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #463e7a;
  border-left-color: #463e7a;
  background-image: url('../src/assets/img/back.png');
  background-color: #191939;
  background-repeat: round;
}
.wrapper {
  max-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.indexMainText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 25px 0;
  min-height: 80vh;
}
.indexMainTitle {
  font-size: 75px;
  font-weight: 800;
  color: #d9c4ca;
}
.indexMainSubtitle {
  font-size: 15px;
  color: #d9c4ca;
}
.MainBanner {
  margin-top: 5rem;
  animation: crescendo 3s alternate infinite ease-in;
}
.video-main {
  margin-top: 5rem;
  border-radius: 20px;

}

@keyframes crescendo {
  100% {transform: scale(1.06);}
}

@media screen and (max-width: 480px) {
  .content {
    border-left-style: none;
    border-bottom-style: none;
    border-right-style: none;
    border-top-left-radius: 0;
  }
  /* .video-main {
    width: 20.5rem;
  } */
  .MainBanner {
    width: 20.5rem;
  }
  .video-main {
    width: 20.5rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 600px) {
  .content {
    border-left-style: none;
    border-top-left-radius: none;
  }
  /* .video-main {
    width: 20.5rem;
  } */
  .MainBanner {
    width: 20.5rem;
  }
  .video-main {
    width: 20.5rem;
  }
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  /* .video-main {
    width: 44.5rem;
  } */
  .MainBanner {
    width: 44.5rem;
  }
  .video-main {
    width: 44.5rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1366px) {
  /* .video-main {
    width: 50.5rem;
  } */
  .MainBanner {
    width: 50.5rem;
  }
  .video-main {
    width: 50.5rem;
  }
}
@media screen and (min-width: 1367px) {
  .video-main {
    width: 63.5rem;
  }
  .MainBanner {
    width: 63.5rem;
  }
  
}